import { useState } from 'react';
import './Request.css';
import emailjs from "@emailjs/browser";
import { useAuth } from '../../contexts/AuthContext';

const Request = ({ showConfirmation }) => {
  const {currentUser} = useAuth();


  const startState = {
    dateFrom: '', 
    dateTo: '',
    first1: '',
    first2: '',
    first3: '',
    first4: '',
    first5: '',
    first6: '',
    first7: '',
    second1: '',
    second2: '',
    second3: '',
    second4: '',
    second5: '',
    second6: '',
    second7: '',
    third1: '',
    third2: '',
    third3: '',
    third4: '',
    third5: '',
    third6: '',
    third7: '',
    fourth1: '',
    fourth2: '',
    fourth3: '',
    fourth4: '',
    fourth5: '',
    fourth6: '',
    fourth7: '',
    fifth1: '',
    fifth2: '',
    fifth3: '',
    fifth4: '',
    fifth5: '',
    fifth6: '',
    fifth7: '',
    sixth1: '',
    sixth2: '',
    sixth3: '',
    sixth4: '',
    sixth5: '',
    sixth6: '',
    sixth7: '',
    seventh1: '',
    seventh2: '',
    seventh3: '',
    seventh4: '',
    seventh5: '',
    seventh6: '',
    seventh7: '',
    eighth1: '',
    eighth2: '',
    eighth3: '',
    eighth4: '',
    eighth5: '',
    eighth6: '',
    eighth7: '',
    ninth1: '',
    ninth2: '',
    ninth3: '',
    ninth4: '',
    ninth5: '',
    ninth6: '',
    ninth7: '',
  }
  
  const [state, setState] = useState(startState);

  const handleSubmit = () => {
    const serviceId = "service_kgdgr99";
    const templateId = "template_46q07u7";
    const userId = "Pxuhw11cdr08Uyi7t";



    const emailParams = {
      login: currentUser.email,
      dateFrom: state.dateFrom,
      dateTo: state.dateTo,
      first1: state.first1,
      first2:state.first2,
      first3:state.first3,
      first4:state.first4,
      first5:state.first5,
      first6:state.first6,
      first7:state.first7,

      second1: state.second1,
      second2:state.second2,
      second3:state.second3,
      second4:state.second4,
      second5:state.second5,
      second6:state.second6,
      second7:state.second7,

      third1: state.third1,
      third2:state.third2,
      third3:state.third3,
      third4:state.third4,
      third5:state.third5,
      third6:state.third6,
      third7:state.third7,

      fourth1:state.fourth1,
      fourth2:state.fourth2,
      fourth3:state.fourth3,
      fourth4:state.fourth4,
      fourth5:state.fourth5,
      fourth6:state.fourth6,
      fourth7:state.fourth7,

      fifth1:state.fifth1,
      fifth2:state.fifth2,
      fifth3:state.fifth3,
      fifth4:state.fifth4,
      fifth5:state.fifth5,
      fifth6:state.fifth6,
      fifth7:state.fifth7,

      sixth1:state.sixth1,
      sixth2:state.sixth2,
      sixth3:state.sixth3,
      sixth4:state.sixth4,
      sixth5:state.sixth5,
      sixth6:state.sixth6,
      sixth7:state.sixth7,

      seventh1:state.seventh1,
      seventh2:state.seventh2,
      seventh3:state.seventh3,
      seventh4:state.seventh4,
      seventh5:state.seventh5,
      seventh6:state.seventh6,
      seventh7:state.seventh7,

      eighth1:state.eighth1,
      eighth2:state.eighth2,
      eighth3:state.eighth3,
      eighth4:state.eighth4,
      eighth5:state.eighth5,
      eighth6:state.eighth6,
      eighth7:state.eighth7,

      ninth1:state.ninth1,
      ninth2:state.ninth2,
      ninth3:state.ninth3,
      ninth4:state.ninth4,
      ninth5:state.ninth5,
      ninth6:state.ninth6,
      ninth7:state.ninth7,

    };

    emailjs.send(serviceId, templateId, emailParams, userId).then(
      (result) => {
        setState(startState)
        showConfirmation()
      },
      (error) => {
        throw new Error('Ошибка сети');
      }
    );
  }

  return (
    <>
      <table className='profile__table'>
        <tr className='profile__table-items'>
        <td colspan='8' className='profile__table-item'>
            Период оказания услуг: с <input value={state.dateFrom} onChange={(e) => setState({ ...state, dateFrom: e.target.value })} type='date' /> по{' '}
            <input value={state.dateTo} onChange={(e) => setState({ ...state, dateTo: e.target.value })} type='date' />{' '}
          </td>
        </tr>

        <tr className='profile__table-items'>
          <th className='request__table-item'>№ п/п</th>
          <th className='request__table-item'>
            Марка и модель транспортного средства
          </th>
          <th className='request__table-item'>
            Комплектация транспортного средства
          </th>
          <th className='request__table-item'>
            Государственный регистрационный номер транспортного средства
          </th>
          <th className='request__table-item'>
            Адрес подачи транспортного средства
          </th>
          <th className='request__table-item'>Время начала оказания услуг</th>
          <th className='request__table-item'>Время окончания окозания услуг</th>
          <th className='request__table-item'>Примечание</th>
        </tr>

        <tr className='profile__table-items'>
          <th className='profile__table-item'>1</th>
          <td className='profile__table-item '>
            <textarea name='' id='' rows='2' value={state.first1} onChange={(e) => setState({ ...state, first1: e.target.value })}></textarea>
          </td>
          <td className='profile__table-item '>
            <textarea name='' id='' rows='2' value={state.first2} onChange={(e) => setState({ ...state, first2: e.target.value })}></textarea>
          </td>
          <td className='profile__table-item '>
            <textarea name='' id='' rows='2' value={state.first3} onChange={(e) => setState({ ...state, first3: e.target.value })}></textarea>
          </td>
          <td className='profile__table-item '>
            <textarea name='' id='' rows='2' value={state.first4} onChange={(e) => setState({ ...state, first4: e.target.value })}></textarea>
          </td>
          <td className='profile__table-item '>
            <input className='input__time' type='time' value={state.first5} onChange={(e) => setState({ ...state, first5: e.target.value })} />
          </td>
          <td className='profile__table-item '>
            <input className='input__time' type='time' value={state.first6} onChange={(e) => setState({ ...state, first6: e.target.value })} />
          </td>
          <td className='profile__table-item '>
            <textarea name='' id='' rows='2' value={state.first7} onChange={(e) => setState({ ...state, first7: e.target.value })}></textarea>
          </td>
        </tr>
        <tr className='profile__table-items'>
          <th className='profile__table-item'>2</th>
          <td className='profile__table-item '>
            <textarea name='' id='' rows='2' value={state.second1} onChange={(e) => setState({ ...state, second1: e.target.value })}></textarea>
          </td>
          <td className='profile__table-item '>
            <textarea name='' id='' rows='2' value={state.second2} onChange={(e) => setState({ ...state, second2: e.target.value })}></textarea>
          </td>
          <td className='profile__table-item '>
            <textarea name='' id='' rows='2' value={state.second3} onChange={(e) => setState({ ...state, second3: e.target.value })}></textarea>
          </td>
          <td className='profile__table-item '>
            <textarea name='' id='' rows='2' value={state.second4} onChange={(e) => setState({ ...state, second4: e.target.value })}></textarea>
          </td>
          <td className='profile__table-item '>
            <input className='input__time' type='time' value={state.second5} onChange={(e) => setState({ ...state, second5: e.target.value })} />
          </td>
          <td className='profile__table-item '>
            <input className='input__time' type='time' value={state.second6} onChange={(e) => setState({ ...state, second6: e.target.value })} />
          </td>
          <td className='profile__table-item '>
            <textarea name='' id='' rows='2' value={state.second7} onChange={(e) => setState({ ...state, second7: e.target.value })}></textarea>
          </td>
        </tr>

        <tr className='profile__table-items'>
          <th className='profile__table-item'>3</th>
          <td className='profile__table-item '>
            <textarea name='' id='' rows='2' value={state.third1} onChange={(e) => setState({ ...state, third1: e.target.value })}></textarea>
          </td>
          <td className='profile__table-item '>
            <textarea name='' id='' rows='2' value={state.third2} onChange={(e) => setState({ ...state, third2: e.target.value })}></textarea>
          </td>
          <td className='profile__table-item '>
            <textarea name='' id='' rows='2' value={state.third3} onChange={(e) => setState({ ...state, third3: e.target.value })}></textarea>
          </td>
          <td className='profile__table-item '>
            <textarea name='' id='' rows='2' value={state.third4} onChange={(e) => setState({ ...state, third4: e.target.value })}></textarea>
          </td>
          <td className='profile__table-item '>
            <input className='input__time' type='time' value={state.third5} onChange={(e) => setState({ ...state, third5: e.target.value })} />
          </td>
          <td className='profile__table-item '>
            <input className='input__time' type='time' value={state.third6} onChange={(e) => setState({ ...state, third6: e.target.value })} />
          </td>
          <td className='profile__table-item '>
            <textarea name='' id='' rows='2' value={state.third7} onChange={(e) => setState({ ...state, third7: e.target.value })}></textarea>
          </td>
        </tr>

        <tr className='profile__table-items'>
          <th className='profile__table-item'>4</th>
          <td className='profile__table-item '>
            <textarea name='' id='' rows='2' value={state.fourth1} onChange={(e) => setState({ ...state, fourth1: e.target.value })}></textarea>
          </td>
          <td className='profile__table-item '>
            <textarea name='' id='' rows='2' value={state.fourth2} onChange={(e) => setState({ ...state, fourth2: e.target.value })}></textarea>
          </td>
          <td className='profile__table-item '>
            <textarea name='' id='' rows='2' value={state.fourth3} onChange={(e) => setState({ ...state, fourth3: e.target.value })}></textarea>
          </td>
          <td className='profile__table-item '>
            <textarea name='' id='' rows='2' value={state.fourth4} onChange={(e) => setState({ ...state, fourth4: e.target.value })}></textarea>
          </td>
          <td className='profile__table-item '>
            <input className='input__time' type='time' value={state.fourth5} onChange={(e) => setState({ ...state, fourth5: e.target.value })} />
          </td>
          <td className='profile__table-item '>
            <input className='input__time' type='time' value={state.fourth6} onChange={(e) => setState({ ...state, fourth6: e.target.value })} />
          </td>
          <td className='profile__table-item '>
            <textarea name='' id='' rows='2' value={state.fourth7} onChange={(e) => setState({ ...state, fourth7: e.target.value })}></textarea>
          </td>
        </tr>

        <tr className='profile__table-items'>
          <th className='profile__table-item'>5</th>
          <td className='profile__table-item '>
            <textarea name='' id='' rows='2' value={state.fifth1} onChange={(e) => setState({ ...state, fifth1: e.target.value })}></textarea>
          </td>
          <td className='profile__table-item '>
            <textarea name='' id='' rows='2' value={state.fifth2} onChange={(e) => setState({ ...state, fifth2: e.target.value })}></textarea>
          </td>
          <td className='profile__table-item '>
            <textarea name='' id='' rows='2' value={state.fifth3} onChange={(e) => setState({ ...state, fifth3: e.target.value })}></textarea>
          </td>
          <td className='profile__table-item '>
            <textarea name='' id='' rows='2' value={state.fifth4} onChange={(e) => setState({ ...state, fifth4: e.target.value })}></textarea>
          </td>
          <td className='profile__table-item '>
            <input className='input__time' type='time' value={state.fifth5} onChange={(e) => setState({ ...state, fifth5: e.target.value })} />
          </td>
          <td className='profile__table-item '>
            <input className='input__time' type='time' value={state.fifth6} onChange={(e) => setState({ ...state, fifth6: e.target.value })} />
          </td>
          <td className='profile__table-item '>
            <textarea name='' id='' rows='2' value={state.fifth7} onChange={(e) => setState({ ...state, fifth7: e.target.value })}></textarea>
          </td>
        </tr>

        <tr className='profile__table-items'>
          <th className='profile__table-item'>6</th>
          <td className='profile__table-item '>
            <textarea name='' id='' rows='2' value={state.sixth1} onChange={(e) => setState({ ...state, sixth1: e.target.value })}></textarea>
          </td>
          <td className='profile__table-item '>
            <textarea name='' id='' rows='2' value={state.sixth2} onChange={(e) => setState({ ...state, sixth2: e.target.value })}></textarea>
          </td>
          <td className='profile__table-item '>
            <textarea name='' id='' rows='2' value={state.sixth3} onChange={(e) => setState({ ...state, sixth3: e.target.value })}></textarea>
          </td>
          <td className='profile__table-item '>
            <textarea name='' id='' rows='2' value={state.sixth4} onChange={(e) => setState({ ...state, sixth4: e.target.value })}></textarea>
          </td>
          <td className='profile__table-item '>
            <input className='input__time' type='time' value={state.sixth5} onChange={(e) => setState({ ...state, sixth5: e.target.value })} />
          </td>
          <td className='profile__table-item '>
            <input className='input__time' type='time' value={state.sixth6} onChange={(e) => setState({ ...state, sixth6: e.target.value })} />
          </td>
          <td className='profile__table-item '>
            <textarea name='' id='' rows='2' value={state.sixth7} onChange={(e) => setState({ ...state, sixth7: e.target.value })}></textarea>
          </td>
        </tr>

        <tr className='profile__table-items'>
          <th className='profile__table-item'>7</th>
          <td className='profile__table-item '>
            <textarea name='' id='' rows='2' value={state.seventh1} onChange={(e) => setState({ ...state, seventh1: e.target.value })}></textarea>
          </td>
          <td className='profile__table-item '>
            <textarea name='' id='' rows='2' value={state.seventh2} onChange={(e) => setState({ ...state, seventh2: e.target.value })}></textarea>
          </td>
          <td className='profile__table-item '>
            <textarea name='' id='' rows='2' value={state.seventh3} onChange={(e) => setState({ ...state, seventh3: e.target.value })}></textarea>
          </td>
          <td className='profile__table-item '>
            <textarea name='' id='' rows='2' value={state.seventh4} onChange={(e) => setState({ ...state, seventh4: e.target.value })}></textarea>
          </td>
          <td className='profile__table-item '>
            <input className='input__time' type='time' value={state.seventh5} onChange={(e) => setState({ ...state, seventh5: e.target.value })} />
          </td>
          <td className='profile__table-item '>
            <input className='input__time' type='time' value={state.seventh6} onChange={(e) => setState({ ...state, seventh6: e.target.value })} />
          </td>
          <td className='profile__table-item '>
            <textarea name='' id='' rows='2' value={state.seventh7} onChange={(e) => setState({ ...state, seventh7: e.target.value })}></textarea>
          </td>
        </tr>

        <tr className='profile__table-items'>
          <th className='profile__table-item'>8</th>
          <td className='profile__table-item '>
            <textarea name='' id='' rows='2' value={state.eighth1} onChange={(e) => setState({ ...state, eighth1: e.target.value })}></textarea>
          </td>
          <td className='profile__table-item '>
            <textarea name='' id='' rows='2' value={state.eighth2} onChange={(e) => setState({ ...state, eighth2: e.target.value })}></textarea>
          </td>
          <td className='profile__table-item '>
            <textarea name='' id='' rows='2' value={state.eighth3} onChange={(e) => setState({ ...state, eighth3: e.target.value })}></textarea>
          </td>
          <td className='profile__table-item '>
            <textarea name='' id='' rows='2' value={state.eighth4} onChange={(e) => setState({ ...state, eighth4: e.target.value })}></textarea>
          </td>
          <td className='profile__table-item '>
            <input className='input__time' type='time' value={state.eighth5} onChange={(e) => setState({ ...state, eighth5: e.target.value })} />
          </td>
          <td className='profile__table-item '>
            <input className='input__time' type='time' value={state.eighth6} onChange={(e) => setState({ ...state, eighth6: e.target.value })} />
          </td>
          <td className='profile__table-item '>
            <textarea name='' id='' rows='2' value={state.eighth7} onChange={(e) => setState({ ...state, eighth7: e.target.value })}></textarea>
          </td>
        </tr>

        <tr className='profile__table-items'>
          <th className='profile__table-item'>9</th>
          <td className='profile__table-item '>
            <textarea name='' id='' rows='2' value={state.ninth1} onChange={(e) => setState({ ...state, ninth1: e.target.value })}></textarea>
          </td>
          <td className='profile__table-item '>
            <textarea name='' id='' rows='2' value={state.ninth2} onChange={(e) => setState({ ...state, ninth2: e.target.value })}></textarea>
          </td>
          <td className='profile__table-item '>
            <textarea name='' id='' rows='2' value={state.ninth3} onChange={(e) => setState({ ...state, ninth3: e.target.value })}></textarea>
          </td>
          <td className='profile__table-item '>
            <textarea name='' id='' rows='2' value={state.ninth4} onChange={(e) => setState({ ...state, ninth4: e.target.value })}></textarea>
          </td>
          <td className='profile__table-item '>
            <input className='input__time' type='time' value={state.ninth5} onChange={(e) => setState({ ...state, ninth5: e.target.value })} />
          </td>
          <td className='profile__table-item '>
            <input className='input__time' type='time' value={state.ninth6} onChange={(e) => setState({ ...state, ninth6: e.target.value })} />
          </td>
          <td className='profile__table-item '>
            <textarea name='' id='' rows='2' value={state.ninth7} onChange={(e) => setState({ ...state, ninth7: e.target.value })}></textarea>
          </td>
        </tr>
      </table>

      <button autofocus className='profile__btn' onClick={handleSubmit}>
        Отправить
      </button>
    </>
  )
};

export default Request;