import './Refuse.css';
import emailjs from "@emailjs/browser";
import { useAuth } from '../../contexts/AuthContext';
import { useState } from 'react';

const Refuse = ({ showConfirmation }) => {
  const {currentUser} = useAuth();

  const startState = {
    message: '',
  }

  const [state, setState] = useState(startState);

  const handleSubmit = () => {
    const serviceId = "service_kgdgr99";
    const templateId = "template_ak6twhi";
    const userId = "Pxuhw11cdr08Uyi7t";



    const emailParams = {
      login: currentUser.email,
      message: state.message,
    };

    emailjs.send(serviceId, templateId, emailParams, userId).then(
      (result) => {
        setState(startState)
        showConfirmation()
      },
      (error) => {
        throw new Error('Ошибка сети');
      }
    );
  }
  return <>
    <textarea
      name=''
      id=''
      cols='110'
      rows='10'
      placeholder='Причина отказа от услуги:'
      value={state.message}
      onChange={(e) => setState({...state, message: e.target.value}) }
    ></textarea>
    <button autofocus className='profile__btn' onClick={handleSubmit}>
      Отказаться
    </button>
  </>
};

export default Refuse;
