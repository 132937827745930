import './InfoWedding.css'

const InfoWedding = () => (
  <div class="services__pg">
    <div class="services__page-img services__page-img4"></div>
  <p class="services__description fz-16">
    Аренда свадебной машины – это уникальная возможность сделать
    самый светлый день в вашей жизни настоящим праздником. Ведь
    красивый автомобиль класса "Люкс" это не просто интересная
    деталь, это один из самых важных элементов всего торжества. Если
    вы хотите сделать свою свадьбу незабываемой, тогда обращайтесь в
    нашу компанию. Мы сделаем все возможное, чтобы вы были довольны
    на все 100%. Аренда авто на свадьбу – это лучший способ получить
    максимум удовольствия и решить все свои проблемы с транспортом.
  </p>

  <h3 class="services__description-title">
    В услуги по прокату машин на свадьбу и организации свадебного
    кортежа входит:
  </h3>
  <ul class="services__description-list">
    <li>
      Аренда различных транспортных средств, включая микроавтобусы,
      легковой автопром разных сегментов по престижу;
    </li>
    <li>
      Проведение консультаций клиентов: опытные менеджеры помогут
      определиться с автомобилем, выслушав пожелания заказчика;
    </li>
    <li>
      Вежливый и воспитанный персонал с водительским стажем более 5
      лет, от профессионализма наших сотрудников зависит насколько
      идеальным будет ваш праздник.
    </li>
  </ul>
  <p class="services__description-text">
    Заказав прокат свадебных авто в компании ДЦК ПЛЮС, вы
    подарите праздник себе и своим друзьям. Обратившись к нам, вы
    получите автомобиль в идеальном состоянии, профессионального
    водителя и возможность передвигаться куда угодно вместе со
    своими гостями.
  </p>

  <h3 class="services__description-title">
    Организация идеального свадебного кортежа
  </h3>
  <p class="services__description-text">
    Первым делом, мы просчитаем количество машин, исходя из числа
    приглашенных, во-вторых, посоветуем интересные для посещения
    места, где можно сделать восхитительное фото, в-третьих,
    обеспечим весь кортеж профессиональными водителями.
  </p>
  <p class="services__description-text">
    Количество машин рассчитывается четко, исходя из количества
    гостей. Мы также помогаем в организации интересной свадебной
    прогулки, которая позволит сделать превосходные снимки. Вместе с
    прокатом машины вы пользуетесь услугами водителей с богатым
    опытом работы: каждый из них отлично знает свой автомобиль,
    общается вежливо и спокойно.
  </p>
  <p class="services__description-text">
    На сайте выложена вся информация по предлагаемым транспортным
    средствам, ценам и условиям проката. Также вы можете задать
    любой вопрос нашим менеджерам, позвонив по телефону или заполнив
    заявку на веб-сайте.
  </p>
</div>
);

export default InfoWedding;