import './Contacts.css';

import Title from '../Title/Title';
import Contact from '../Contact/Contact';
import img1 from '../../img/map.png';
import img2 from '../../img/call.png';
import img3 from '../../img/clock.png';
import img4 from '../../img/mail.png';

const contact = [
  {
    image: img1,
    title: 'Адрес :',
    text: 'г. Москва, ул. Басовская, д. 16, стр. 1, помещ. VIIА, ком. 21  ',
  },
  {
    image: img2,
    title: 'Телефон :',
    text: '+7 (915) 184-45-81',
  },
  {
    image: img3,
    title: 'Время работы :',
    text: 'пн - пт: с 08:30 до 21:00, сб - вс: с 10:00 до 18:00.',
  },
  {
    image: img4,
    title: 'E-mail :',
    text: 'dccplus@yandex.ru',
  },
];

const Contacts = () => (
  <section className='contacts' id='сontacts'>
    <Title text='Контакты' />
    <div className='container'>
      <div className='contacts__text'>
        <h3>ДЦК ПЛЮС в Москве:</h3>
      </div>

      <div className='contacts__items'>
        {contact.map((contact, index) => (
          <Contact
            image={contact.image}
            title={contact.title}
            text={contact.text}
            key={index}
          />
        ))}
      </div>
    </div>
  </section>
);

export default Contacts;
