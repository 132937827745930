import "./DownloadDoc.css";

const DownloadDoc = ({showDownload}) => (
  <>
  <div class='download'>
    <button autofocus class='download__btn' onClick={showDownload}>
      Скачать Акт-приемки оказанных услуг
    </button>
    <button autofocus class='download__btn' onClick={showDownload}>
      Скачать Копии путевых листов
    </button>
    <button autofocus class='download__btn' onClick={showDownload}>
      Скачать Счет на оплату
    </button>
    <button autofocus class='download__btn' onClick={showDownload}>
      Скачать Счет-фактуру
    </button>
    </div>
  </>
);
export default DownloadDoc;
